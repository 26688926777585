.box-steps {
    position: relative;
    margin-bottom: 30px;
    &::before {
        content: '';
        position: absolute;
        top: 17px;
        right: -17px;
        @include size(35px);
        @include imageBg(
            $image:'arrow-right.png',
            $size: cover
        );
    }
    &:last-child {
        &::before {
            content: none;
            display: none;
        }
    }   

    .card-steps {
        position: relative;
        padding: 80px 10px 0;
        text-align: center;
        &::before {
            counter-increment: section;
            content: '0'counter(section);
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            font-family: $text-font-secondary;
            font-size: 80px;
            font-weight:weight(bold); 
            color: rgba($text-color-light,0.2);
            line-height: em(1px,1px);
            letter-spacing: -4px;
            pointer-events: none;
        }
        .card-steps_title {
            font-family: $text-font;
            font-size: 18px;
            font-weight: weight(regular); 
            color: $brand-color-third;
            line-height: em(24px,18px);
            text-transform: none;
            letter-spacing: -0.5px;
        }
    }

    @include breakpoint-max(container) {
        width: 33.33333333%;
        &:nth-child(3) {
            &::before {
                content: none;
            }
        }
    }
    @include breakpoint-max(medium) {
        width: 50%;
        &:nth-child(2),
        &:nth-child(4) {
            &::before {
                content: none;
            }
        }
        &:nth-child(3) {
            &::before {
                content: '';
            }
        }
    }
    @include breakpoint-max(xsmall) {
        width: 100%;
        &::before {
            content: none !important;
        }
        .card-steps {
            padding-top: 10px;
            padding-left: 55px;
            text-align: left;
            &:before {
                top: 0;
                left: 20px;
                font-size: 50px;
            }
        }
    }
}