.dataTables_scrollHead {
    display: none !important;
}

.dataTables_sizing {
    height: auto !important;
    overflow: visible !important;
}

.dataTables_info {
    display: none !important;
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 0;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    tr {
        text-align: center;
        td,
        th {
            border-left: solid 2px $border-color;
            padding: 13px 10px 11px !important;
            vertical-align: middle;
            width: auto !important;
            height: auto !important;
            &:first-child {
                border-left: 0;
            }
        }
    }
    thead {
        tr {
            color: #fff;
            background-color: $brand-color-third;
            height: auto !important;
            td,
            th {
                border-color: #129051;
            }
        }
    }
    tbody {
        overflow-x: scroll;
        tr {
            border-bottom: 2px solid $border-color;
        }
    }
}

table tr:first-child td {
    border-top: 0;
}