.home-cta {
    position: relative;
    padding: 210px 0 180px;
    @include imageBg(
        $image: 'bg-cta.jpg',
        $size: cover
    );
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        @include size(100%,180px);
        @include imageBg(
            $image: 'waves-white.png',
            $size: cover
        );
    }
    .cta-box,
    .box-downapp {
        margin-left: 70px
    }
    .cta-box {
        max-width: 720px;
        .cta-box_title {
            font-size: 70px;
            margin: 20px 0 0;
        }
        .cta-box_subtitle {
            font-family: $text-font;
            font-size: 36px;
            font-weight: weight(regular);
            color: #fff;
            line-height: em(46px,36px);
            text-transform: none;
            margin: 20px 0 0;
        }
    }
    .box-downapp {
        margin-top: 45px;
        .box-downapp_img {
            .pc {
                box-shadow: none;
            }
        }
    }
    @include breakpoint-max(1500px) {
        background-position-x: -360px;
        .cta-box,
        .box-downapp {
            margin-left: 0px
        }
        .cta-box {
            max-width: 620px;
            .cta-box_title {
                font-size: 60px;
            }
        }
    }
    @include breakpoint-max(1300px) {
        background-position-x: -420px;
        .cta-box {
            max-width: 520px;
            .cta-box_title {
                font-size: 55px;
            }
            .cta-box_subtitle {
                font-size: 30px;
            }
        }
    }
    @include breakpoint-max(container) {
        padding-top: 450px;
        padding-bottom: 70px;
        background-image: url(../images/backgrounds/bg-cta-mobile.jpg);
        background-size: initial;
        background-position: center top;
        background-color: $color-3;
        .container-fluid {
            position: relative;
            background-color: $color-3;
            &::before {
                content: '';
                position: absolute;
                top: -160px;
                left: 50%;
                transform: translateX(-50%);
                @include size(100%,160px);
                @include imageBg(
                    $image: 'waves-orange.png',
                );
            }
        }
        .cta-box {
            max-width: 1200px;
            text-align: center;
        }
    }
}

.home-steps {
    .home-steps_title {
        font-size: 36px;
        color: $brand-color-third;
        text-align: center;
        margin: 0 0 25px;
    }
    .row {
        counter-reset: section;
    }
}

.home-price {
    position: relative;
    padding: 60px 0 0;
    margin: 0 0 60px;
    &::before {
        content: '';
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 1170px;
        @include size(100%,10px);
        @include imageBg(
            $image: 'waves-divider.png',
            $repeat: repeat-x
        );
    }
    .home-price_title {
        margin: 0 0 15px;
    }
    .row + .row {
        margin-top: 30px;
    }
    .grid-7,
    .grid-5 {
        @include flex(
            $align: center
        );
    }
    .grid-7 {
        justify-content: flex-start;
    }
    .grid-5 {
        justify-content: center
    }
    .home-price_link {
        display: inline-block;
        @media(hover: hover) {
            .home-price_pix {
                figcaption {
                    @include transition;
                }
            }
            &:hover {
                .home-price_pix {
                    figcaption {
                        color: $brand-color;
                    }
                }
            }
        }
    }
    .home-price_pix {
        @include flex(
            $align: flex-start,
            $justify: flex-start
        );
        figcaption {
            width: calc(100% - 135px);
            margin-left: 10px;
            color: $text-color-light;
            strong {
                text-decoration: underline;
            }
        }
    }
    @include breakpoint-max(container) { 

        .grid-4,
        .grid-5,
        .grid-7,
        .grid-8 {
            width: 100%;
            text-align: center;
        }
        .grid-4,
        .grid-8 {
            margin-top: 30px;
        }
        .home-price_pix {
            figcaption {
                max-width: 310px;
                text-align: left;
            }
        }
    }
    @include breakpoint-max(xsmall) {
        .home-price_pix {
            flex-direction: column;
            align-items: center;
            figcaption {
                max-width: 570px;
                width: 100%;
                text-align: center;
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }
}

.home-coupon {
    .card-coupon {
        background-color: $color-1;
        padding: 30px 25px 0;
        border-radius: 20px;
        .card-coupon_title {
            position: relative;
            font-size: 38px;
            color: #fff;
            z-index: 1;
            span {
                position: relative;
                display: inline-block;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -4px;
                    @include size(calc(100% + 8px),100%);
                    background-color: $brand-color-third;
                    border-radius: 3px;
                    z-index: -1;
                }
            }
        }
    }
    .card-coupon + .row {
        margin-top: 60px;
    }
    .grid-5,
    .grid-7 {
        @include flex(
            $align: center            
        );
    }
    .grid-7 {
        justify-content: center
    }
    @include breakpoint-max(container) {
        margin-bottom: 60px;
        .grid-5,
        .grid-7 {
            width: 100%;
        }
        .grid-5 {
            text-align: center;
            justify-content: center;
            margin-bottom: 20px;
        }
    }
}

.home-schedules {
    position: relative;
    padding: 30px 0;
    background-color: $color-1;
    text-align: center;
    &:before {
        content: '';
        position: absolute;
        top: -160px;
        left: 0;
        @include size(100%,190px);
        @include imageBg(
            $image: 'waves-2.png',
            $size: cover
        );
        z-index: -1;
    }
    .home-schedules_title {
        font-family: $text-font;
        font-size: 26px;
        color: #fff;
        line-height: em(36px,26px);
        text-transform: none;
        margin: 0 0 30px;
    }
    @include breakpoint-max(medium) {
        padding-top: 10px;
    }
}


.single-details {
    @include clearfix;
    p, ul, ol {
        color: $text-color-light;
        font-size: $font-size-default;
        line-height: em(26px, $font-size-default);
        margin-bottom: 25px;
    }
    h2, h3, h4 {
        color: $brand-color;
        margin-bottom: 15px;
        line-height: em(18px, $font-size-default);
    }
    h2 {
        font-size: 26px;
    }
    h3 {
        font-size: 22px;
    }
    h4 {
        font-size: 18px;
    }
    img {
        margin-bottom: 25px;
    }
    ul {
        li {
            & + li {
                margin-top: 6px;
            }
            position: relative;
            display: table;
            padding-left: 20px;
            &::before {
                @include pseudo;
                @include iconeBg(
                    $content: '',
                    $fontSize: 18px
                );
                color: $brand-color;
                top: 2px;
                left: 0;
            }
            h3 {
                display: block;
                width: 100%;
                font-size: 18px;
                font-weight: weight(bold);
                line-height: em(26px,18px);
                color: $brand-color;
                text-transform: uppercase;
                margin-bottom: 0;
            }
            p {
                margin-bottom: 15px;
            }
        }
    }
    blockquote {
        position: relative;
        border-left: 5px solid $brand-color;
        background-color: $bg-gray;
        padding: 20px;
        margin-bottom: 30px;
        p {
            margin-bottom: 0;
            & + p {
                margin-top: 25px;
            }
        }
    }
}