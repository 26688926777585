// Font Size Default
$font-size-default: 16px;

// Font family Primary
$text-font: 'Lexend Deca', sans-serif !default;
$text-font-secondary: 'Baloo 2', sans-serif !default;

$font-primary-weight: (
  'light': 300,
  'regular': 400,
  'medium': 500,
  'sbold': 600,
  'bold': 700,
  'xbold': 800,
  'xxbold': 900,
) !default;

$font-secondary-weight: (
  'bold': 700
) !default;


// Text color light
$text-color-light: #98A1AB !default;

// Copy text color dark
$text-color-dark: #000 !default;

// Brand color
$brand-color: #EA1566 !default;

// Brand color secondary
$brand-color-secondary: #F38120 !default;

$brand-color-third: #3F56A4;

$color-1: #7CBCAD;

$color-2: #20B9D4;

$color-3: #f9ad1b;

$bg-gray: #f1f1f1;

// Container's maximum width
$max-width: 1200px !default;

// Error
$error: #cc0000 !default;

// Success
$success: #27ae60 !default;

// Border Color
$border-color: #e5e5e5 !default;

//Social Color
$whatsapp: #5EB946;
$facebook: #32529f;
$instagram: #f00075;
$twitter: #1da1f2;
$youtube: #f8462e;
$linkedin: #0077b5;
$flickr: #ff0084;
$tiktok: #fe2c55;
$skype: #0078d7;
$envelope: #e60023;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-max: (
  'xsmall':  (max-width: 414px),
  'small':  (max-width: 575px),
  'medium': (max-width: 767px),
  'large':  (max-width: 991px),
  'xlarge':  (max-width: 1023px),
  'container': (max-width: ($max-width - 1px))
) !default;

// Breakpoints map
// @prop {String} keys - Keys are identifiers mapped to a given length
// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-min: (
  'xsmall':  (min-width: 415px),
  'small':  (min-width: 576px),
  'medium': (min-width: 768px),
  'large':  (min-width: 992px),
  'xlarge':  (min-width: 1024px),
  'container': (min-width: $max-width)
) !default;