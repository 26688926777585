.site-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    .logo-client {
        display: inline-block;
        background-color: #fff;
        padding: 35px 25px;
        border-radius: 0px 0px 10px 10px;
        margin-left: 70px;
    }
    @include breakpoint-max(1500px) {
        .logo-client {
            margin-left: 0;
        }
    }
}