.box-time {
    margin-bottom: 30px;
    .card-time {
        padding: 22px 15px;
        border: 2px solid #fff;
        border-radius: 5px;
        text-align: center;
        .card-time_title {
            font-family: $text-font;
            font-size: 20px;
            color: #fff;
        }
    }
    @include breakpoint-max(xlarge) {
        width: 50%;
    }
    @include breakpoint-max(medium) {
        width: 100%;
    }
}