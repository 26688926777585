.box-price {
    text-align: center;
    span {
        font-size: 30px;
        display: inline-block;
        margin-top: -15px;
    }
    .box-price_title {
        font-family: $text-font-secondary;
        font-size: 100px;
        color: $brand-color;
        line-height: em(15px,20px);
        letter-spacing: -5px;
        margin-top: 10px;
        small {
            font-family: $text-font;
            font-size: 30px;
            font-weight: weight(bold);
            letter-spacing: -2px;
            margin-right: -8px;
        }
    }
}