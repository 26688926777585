// Generator Keyframes
@mixin keyframes($name) {
	@keyframes #{$name} {
		@content;
	}
}


// FlexBox
@mixin flex(
    $align: false,
    $justify: false,
    $direction: row,
    $wrap: wrap
) {
	@if($align) {
		align-items: $align;
	}
	display: flex;
	@if($direction) {
		flex-direction: $direction;
	}
	flex-wrap: $wrap;
	
	@if($justify) {
		justify-content: $justify;
	}
}

@mixin  pseudo(
	$display: false,
	$pos: absolute
) {
	position: $pos;
	@if($display) {
		display: $display;
	}
}


// Helper to size an element
// @author Hugo Giraudel
// @param {Length} $width
// @param {Length} $height
@mixin size($width, $height: $width) {
	height: $height;
	width: $width;
}

// Helper to import images
@mixin imageBg(
	$image: false,
	$pos: center center,
	$size: false,
	$repeat: no-repeat,
	$att: false
) {
	@if($image) {
		background-image: url(../images/backgrounds/#{$image});
	}
	background-position: $pos;
	background-repeat: $repeat;
	@if($size) {
		background-size: $size;
	}
	@if($att) {
		background-attachment: $att;
	}
}

// Gradient
// Usage:
// div {
// 		@include gradient(#ff00ff, #ff00cc, vertical);
// }
@mixin gradient($start-color, $end-color, $orientation) {
	background: $start-color;
	@if $orientation == vertical {
		// vertical
		background: linear-gradient(to bottom,  rgba($start-color,1) 0%,rgba($end-color,1) 100%);
	} @else if $orientation == horizontal {
		// horizontal
		background: linear-gradient(to right,  rgba($start-color,1) 0%,rgba($end-color,1) 100%);
	} @else {
		// radial
		background: radial-gradient(ellipse at center,  rgba($start-color,1) 0%,($end-color,1) 100%);
	}
	
}


// Transition
@mixin transition(
	$prop: all,
	$duration: 0.3s,
	$function: ease
) {
	transition: $prop $duration $function;
}


// Carregar Icone BG (Icomoon)
@mixin iconeBg(
    $content: "",
    $fontSize: false
) {
	@if($content) {
		content: $content;
	}
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
	line-height: 1;
	@if($fontSize) {
		font-size: $fontSize;
	}
}


// Mixin to manage responsive breakpoints
// @require $breakpoints-max
@mixin breakpoint-max($breakpoint: false) {
	@if($breakpoint != false) {
		@if map-has-key($breakpoints-max, $breakpoint) {
			@media #{inspect(map-get($breakpoints-max, $breakpoint))} {
				@content;
			}
		} @else if($breakpoint) {
			@media (max-width: $breakpoint) {
				@content;
			}
		} @else {
			@warn "Nenhum valor encontrado para `#{$breakpoint}`. " + "Breakpoints disponiveis: #{map-keys($breakpoints-max)}.";
		}
		
	} @else {
		@warn "Informe um valor para `$breakpoint`. Breakpoints disponiveis: #{map-keys($breakpoints-max)} ou um valor customizado em px (Ex.: 767px)";
	}
}

// Mixin to manage responsive breakpoints
// @require $breakpoints-min
@mixin breakpoint-min($breakpoint: false) {
	@if($breakpoint != false) {
		@if map-has-key($breakpoints-min, $breakpoint) {
			@media #{inspect(map-get($breakpoints-min, $breakpoint))} {
				@content;
			}
		} @else if($breakpoint) {
			@media (min-width: $breakpoint) {
				@content;
			}
		} @else {
			@warn "Nenhum valor encontrado para `#{$breakpoint}`. " + "Breakpoints disponiveis: #{map-keys($breakpoints-min)}.";
		}
		
	} @else {
		@warn "Informe um valor para `$breakpoint`. Breakpoints disponiveis: #{map-keys($breakpoints-min)} ou um valor customizado em px (Ex.: 767px)";
	}
}


// Placehlders
@mixin placeholder {
	&::-webkit-input-placeholder {@content};
	&:-moz-placeholder {@content};
	&::-moz-placeholder {@content};
	&:-ms-input-placeholder {@content};
}


@mixin triangle($direction, $color: currentcolor, $size: 1em) {
  @if not index(top right bottom left, $direction) {
    @error "Direction must be either 'top', 'right', 'bottom' or 'left'.";
}

  content: '';
  width: 0;
  height: 0;
  z-index: 2;
  border-#{opposite-position($direction)}: ($size * 1.5) solid $color;
  
  $perpendicular-borders: $size solid transparent;
  
  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
}

// Mixin itself
@mixin clearfix() {
	&::after {
	  display: block;
	  content: "";
	  clear: both;
	}
}

@mixin magic-border($width, $color, $duration, $direction){
	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		@include size(100%);
		z-index: 5;

	}
	&::before {
		border: 4px solid $border-color;
	}
	&::after {
		background:linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to top, $color 50%, transparent 50%), linear-gradient(to right, $color 0%, $color 100%), linear-gradient(to left, $color 0%, $color 100%);
		background-size:100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
		background-position:50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
		background-repeat:no-repeat, no-repeat;
		transition:transform $duration ease-in-out, background-position $duration ease-in-out, background-size $duration ease-in-out;
		transform:scaleX(0) rotate(180deg * $direction);
		transition-delay:$duration*2, $duration, 0s;
	}
}

@mixin magic-border-hover($width, $duration, $direction) {
	&::after {
		background-size:200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
		background-position:50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
		transform:scaleX(1) rotate(180deg * $direction);
		transition-delay:0s, $duration, $duration*2;
	}
}