.box-downapp {
    @include flex(
        $align: center,
        $justify: flex-start
    );
    .box-downapp_title {
        font-size: 22px;
        font-weight: weight(bold);
        color: $text-color-dark;
        line-height: em(30px,22px);
        max-width: 420px;
        &.mobile {
            display: none;
        }
    }
    .box-downapp_img {
        margin-left: 45px;
        .pc {
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0 20px 30px rgba($text-color-light, 0.15);
        }
        .google,
        .apple {
            display: none;
        }
    }
    @include breakpoint-max(container) {
        flex-direction: column;
        text-align: center;
        .box-downapp_title {
            max-width: 1170px;
            display: none;
            &.mobile {
                display: block;
            }
        }
        .box-downapp_img {
            position: relative;
            margin-left: 0;
            margin-top: 15px;
            z-index: 5;
            .pc {
                display: none;
            }
            .google,
            .apple {
                display: inline-block;
            }
        }
    }
}

.site-foot {
    .box-downapp {
        .box-downapp_title {
            font-size: 14px;
            max-width: 270px;
            @include breakpoint-max(container) {
                max-width: 1170px;
            }
        }
    }
}