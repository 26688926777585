.head-sec {
    margin: 0 0 40px;
    .head-sec_title {
        font-size: 52px;
        color: $brand-color-secondary;
    }
    .head-sec_subtitle {
        font-family: $text-font;
        font-size: 26px;
        font-weight: weight(regular);
        color: $text-color-light;
        line-height: em(32px,26px);
        text-transform: none;
        margin: 20px 0 0;
        strong {
            font-weight: weight(bold);
        }
    }
    @include breakpoint-max(container) {
        text-align: center;
    }
}