body {
    font-family: $text-font;
}

h1, h2, h3, h4, h5, h6 {
    font-family: $text-font-secondary;
    text-transform: uppercase;
}

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="file"],
input[type="search"],
select,
textarea {
    color: $text-color-light;
    @include placeholder {
        color: #98a1ab;
        opacity: 1;
    }
    @include transition;
    &:focus {

        box-shadow: 0 20px 30px rgba($text-color-dark, 0.05);
    }
}

a {
    @media(hover: hover) {
        @include transition;
        &:hover {
            color: $brand-color-secondary;
        }
    }
}

form {
    .field {
        margin-bottom: 20px;
        label {
            display: block;
            position: relative;
            font-size: 16px;
            span.label {
                display: block;
                margin-bottom: 5px;
                strong {
                    color: $error;
                }
            }
            &.errorField {
                p {
                    @include pseudo;
                    top: calc(100% + 2px);
                    right: 0;
                    color: $error;
                    font-size: 16px;
                }
            }
        }
        .form-control {
            @include transition;
            &:focus {
                border-color: darken($border-color, 10%);
            }
        }
        @include breakpoint-max(medium) {
            width: 100%;
        }
    }
}


// Form Alerts
.form-alert {
    box-sizing: border-box;
    @include flex(
        $direction: column,
        $align: center,
        $justify: center
    );
    padding: 30px;
    text-align: center;
    width: 100%;
    svg {
        height: 50px;
        margin: 0 0 10px;
        width: 50px;
        path {
            fill: $brand-color;
        }
    }
    h2 {
        font-size: 32px;
        font-weight: weight(regular);
        line-height: 1.3em;
        margin: 0;
    }
    span {
        display: block;
        font-size: 24px;
        font-weight: weight(regular);
        line-height: 1.4em;
    }
    &.success {
        background-color: rgba($success, 0.1);
        border: solid 1px rgba($success, 0.12);
        color: $success;
        svg {
            path {
                fill: #075E54;
            }
        }
        h2 {
            color: #075E54;
        }
    }
    &.error {
        background-color: rgba($error, 0.1);
        border: solid 1px rgba($error, 0.12);
        color: $error;
        svg {
            path {
                fill: $error;
            }
        }
        h2 {
            color: $error;
        }
    }
}

.logo-tdh {
    display: inline-block;
    opacity: 0.6;
    @media(hover: hover) {
        @include transition;
        &:hover {
            opacity: 1;
        }
    }
}

.logo-client {
    display: table;
    @media(hover: hover) {
        @include transition;
        &:hover {
            opacity: 0.7;
        }
    }
    @include breakpoint-max(container) {
        margin: 0 auto 15px;
    }
}

.back-top {
    display: inline-block;
    position: relative;
    font-size: 16px;
    font-weight: weight(sbold);
    color: $brand-color-third;
    padding-right: 25px;
    margin: 10px 0 0;
    &::after {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        @include iconeBg(
            $content: '',
            $fontSize: 13px
        );
        color: $brand-color-third;
    }
    @media(hover: hover) {
        @include transition;
        &::before {
            position: absolute;
            content: attr(data-hover);
            opacity: 0;
            text-shadow: 0 0 1px rgb(255,255,255 / 30%);
            transform: scale(1.1) translateX(10px) translateY(-10px) rotate(4deg);
            pointer-events: none;
            transition: transform 0.3s, opacity 0.3s;
        }
        &::after {
            @include transition;
        }
        &:hover {
            color: $brand-color-secondary;
            &::before {
                transform: scale(1) translateX(0px) translateY(0px) rotate(0deg);
                opacity: 1;
            }
            &::after {
                color: $brand-color-secondary;
            }
        }
    }
}