@media print {
    .site-header,
    .site-footer,
    .breadcrumbs {
        display: none;
    }
    .weekly-schedule {
        margin-top: 0;
        .head-sec {
            .head-sec_title {
                font-size: 26px;
            }
            .head-sec_subtitle {
                font-size: 16px;
            }
        }
        table {
            tr {
                border-top: 1px solid $border-color;
            }
            p {
                & + p {
                    margin-top: 5px;
                    padding-top: 5px;
                    border-top: 1px solid $border-color;
                }
            }
        }
        .social-share {
            display: none;
        }
    }
}