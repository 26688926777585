/* ----------------------------------------------
 * Generated by Animista on 2021-3-13 14:59:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-vertical-right
 * ----------------------------------------
 */
 @-webkit-keyframes flip-vertical-right {
  0% {
    transform: rotateY(0);
  }
  100% {
      transform: rotateY(360deg);
  }
}
@keyframes flip-vertical-right {
  0% {
      transform: rotateY(0);
  }
  100% {
      transform: rotateY(360deg);
  }
}

/* ----------------------------------------------
* Generated by Animista on 2021-3-14 13:33:17
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation rotate-hor-center
* ----------------------------------------
*/
@-webkit-keyframes rotate-hor-center {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
  }
}
@keyframes rotate-hor-center {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-360deg);
            transform: rotateX(-360deg);
  }
}


/* ----------------------------------------------
* Generated by Animista on 2021-3-15 10:43:9
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation focus-in-expand
* ----------------------------------------
*/
@-webkit-keyframes focus-in-expand {
0% {
  letter-spacing: -0.5em;
  -webkit-filter: blur(12px);
          filter: blur(12px);
  opacity: 0;
}
100% {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  opacity: 1;
}
}
@keyframes focus-in-expand {
0% {
  letter-spacing: -0.5em;
  -webkit-filter: blur(12px);
          filter: blur(12px);
  opacity: 0;
}
100% {
  -webkit-filter: blur(0px);
          filter: blur(0px);
  opacity: 1;
}
}


/* ----------------------------------------------
* Generated by Animista on 2021-3-15 10:47:18
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation heartbeat
* ----------------------------------------
*/
@-webkit-keyframes heartbeat {
from {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
10% {
  -webkit-transform: scale(0.91);
          transform: scale(0.91);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
17% {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
33% {
  -webkit-transform: scale(0.87);
          transform: scale(0.87);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
45% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
}
@keyframes heartbeat {
from {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
10% {
  -webkit-transform: scale(0.91);
          transform: scale(0.91);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
17% {
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
33% {
  -webkit-transform: scale(0.87);
          transform: scale(0.87);
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
}
45% {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}
}


/* ----------------------------------------------
* Generated by Animista on 2021-3-15 10:48:37
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation vibrate-2
* ----------------------------------------
*/
@-webkit-keyframes vibrate-2 {
0% {
  -webkit-transform: translate(0);
          transform: translate(0);
}
20% {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
}
40% {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}
60% {
  -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px);
}
80% {
  -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
}
100% {
  -webkit-transform: translate(0);
          transform: translate(0);
}
}
@keyframes vibrate-2 {
0% {
  -webkit-transform: translate(0);
          transform: translate(0);
}
20% {
  -webkit-transform: translate(2px, -2px);
          transform: translate(2px, -2px);
}
40% {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
}
60% {
  -webkit-transform: translate(-2px, 2px);
          transform: translate(-2px, 2px);
}
80% {
  -webkit-transform: translate(-2px, -2px);
          transform: translate(-2px, -2px);
}
100% {
  -webkit-transform: translate(0);
          transform: translate(0);
}
}