.btn-all {
    @include flex;
    &.center {
        justify-content: center;
    }
    &.right {
        justify-content: flex-end;
    }
}

.btn {
    @media(hover: hover) {
        @include transition;
    }
}

.btn-brand {
    color: #fff;
    background-color: $brand-color;
    @media(hover: hover) {
        &:hover {
            background-color: $brand-color-secondary;
        }
    }
}

.btn-block {
    width: 100%;
}