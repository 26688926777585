.site-foot {
    font-size: 14px;
    padding: 40px 0 20px;
    a {
        color: $color-2;
        @media(hover: hover) {
            &:hover {
                color: $brand-color;
            }
        }
    }
    .foot-logo,
    .foot-copy,
    .foot-app,
    .foot-nav,
    .foot-pay {
        @include flex(
            $align: center
        );
    }
    .foot-logo,
    .foot-copy,
    .foot-nav {
        justify-content: flex-start;
    }
    .foot-copy {
        p + p {
            margin-top: 20px;
        }
    }
    .foot-app,
    .foot-pay {
        justify-content: flex-end;
    }
    .foot-pay {
        figure {
            margin-left: 10px;
        }
    }
    .subfooter {
        margin: 30px 0 0;
        padding: 30px 0 0;
        border-top: 1px solid $border-color;
    }

    @include breakpoint-max(container) {
        .foot-logo,
        .foot-copy,
        .foot-app,
        .foot-nav,
        .foot-pay {
            width: 100%;
            text-align: center;
        }
        .foot-nav,
        .foot-app,
        .foot-pay,
        .foot-copy {
            justify-content: center;
        }
        .foot-pay {
            margin-top: 15px;
            figure {
                margin-left: 0;
                margin-top: 10px;
            }
        }
        .foot-copy {
            margin-top: 20px;
        }
        .foot-app {
            margin-top: 30px;
        }
    }
}